import parse from 'html-react-parser';

function Info({title = '', subtitle = '', text = '', icon = false, close}){
    return (
        <>
            <div className="modal__ct">
                {icon && (
                    <div className="modal__ct-box">
                        <i className="icon__success" aria-hidden={true}/>
                    </div>
                )}
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle">
                            {parse(text)}
                        </div>
                    </div>
                )}
                <div className="modal__ct-box modal__action-close d-flex justify-content-center">
                    <div onClick={()=>{
                        close()
                    }} className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                        <span>Хорошо</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;
