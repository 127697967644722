import {useState} from 'react'
import { Link } from 'react-router-dom'
import {useModal} from "../../context";
import parse from "html-react-parser";

function ImgCheck({check_fd = null, check_fn = null, check_fp = null, img = [], close}){
    const { show, hide} = useModal();

    return (
        <>
            <div className="modal__ct">
                {(check_fd || check_fn || check_fp) && (
                    <div className="modal__ct-box">
                        {check_fd && (
                            <div className="modal__subtitle">
                                ФД: {check_fd}
                            </div>
                        )}
                        {check_fn && (
                            <div className="modal__subtitle">
                                ФН: {check_fn}
                            </div>
                        )}
                        {check_fp && (
                            <div className="modal__subtitle">
                                ФП: {check_fp}
                            </div>
                        )}
                    </div>
                )}

                <div className={'modal__ct-box' + ((!check_fd && !check_fn && !check_fp) ? ' modal__ct-box--indent' : '')}>
                    {img.map((imgIt, i) => (
                        <div className="modal__ct-img">
                            <img src={imgIt}/>
                        </div>
                    ))}
                </div>
                <div className="modal__ct-box modal__action-close d-flex justify-content-center">
                    <div onClick={()=>{
                        close()
                    }} className="btn-ct d-flex align-items-center justify-content-center">
                        <span>Хорошо</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImgCheck;
