import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

function Pass(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { show, hide} = useModal();

    const schema = yup.object({
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
    }).required();

    const { register, setFocus, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const closeModal = () => {
        hide();
    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);

        try {
            const response = await axiosInstance.post('/profiles/api/resend-password', formData);
            if(response.data){
                show(<Info
                        text={'Письмо с&nbsp;паролем отправлено на&nbsp;<b>' + data.email + '</b>'}
                        close={closeModal}/>,
                    "modal--info"
                );
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        напомнить <br className="mb-only"/>пароль
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle">
                        Пароль будет отправлен <br/>
                        на&nbsp;указанную <br className="mb-only"/>электронную почту
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="email"
                                            autoComplete="nope"
                                            {...register("email")}
                                        />
                                        <label for="email" className="form__label">
                                            Электронная почта
                                        </label>
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                    <button disabled={isSubmitting} type="submit" className="btn-ct btn-ct--large btn--full d-flex align-items-center justify-content-center">
                                        <span>отправить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Pass;
