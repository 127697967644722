import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ProfileProvider, ModalProvider, EndProvider} from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import Generator from "./pages/Generator";
/*import End from "./pages/End";*/
import {Helmet} from "react-helmet";
import Winners from "./pages/Winners";

function App() {
  return (
      <>
          <Helmet>
              <title>Поздравьте тех, кто научил важному</title>
              <meta property="og:title" content="Поздравьте тех, кто научил важному"/>
          </Helmet>
          <EndProvider>
              <ProfileProvider>
                  <ModalProvider>
                      <div id="wrapper" className="wrapper">
                          <Header/>
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/cabinet" element={<Cabinet />} />
                              <Route path="/generator" element={<Generator />} />
                              <Route path="/winners" element={<Winners />} />
                              {/*<Route path="/end" element={<End />} />*/}
                          </Routes>
                          <Footer/>
                      </div>
                  </ModalProvider>
              </ProfileProvider>
          </EndProvider>

      </>
  );
}

export default App;
