import React, {useEffect, useState} from "react";
import "keen-slider/keen-slider.min.css"
import * as yup from "yup";
import {useForm} from "react-hook-form";
import axiosInstance from "../helpers/axios";
import {yupResolver} from "@hookform/resolvers/yup";
import useEffectOnce from "../hooks/useEffectOnce";
import {pagination} from "../helpers/pagination";

const perPage = 10;

function Winners() {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winnersType, setWinnersType] = useState(null);
    const [winners, setWinners] = useState([]);
    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        setWinnersType(null);
        getWinners(1, true);
    }

    useEffectOnce(()=>{
        setWinnersType('week');
    });

    useEffect(()=>{
        if(winnersType){
            getWinners(1);
            setValue('email', '');
        }
    }, [winnersType]);

    const getWinners = async(page = 1, search = null) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('/prizes/api/winners?per-page=' + perPage + '&page=' + page + (search ? '&filter[and][1][user_email][like]=' + watchEmail : '&filter[and][0][category][eq]=' + winnersType));
            if(response.data){
                setWinners(response.data.items);
                setWinPaging({
                    total: response.data._meta.pageCount,
                    current_page: response.data._meta.currentPage
                });
                setLoading(false);
                setSearchLoading(false);

                if(search){
                    setWinnersEmpty('Среди победителей данный аккаунт не найден');
                }else{
                    setWinnersEmpty('Победители не определены');
                }
            }
        } catch (error) {
            if(error.response && error.response.data){

            }

            setLoading(false);
            setSearchLoading(false);
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="49" height="49" rx="24.5" transform="matrix(1 0 0 -1 0 49.5)" fill="#720E39"/>
                            <path d="M28 16.4999L20 24.9999L28 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(winPaging.current_page !== parseInt(page)){
                                        getWinners(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="49" y="49.5" width="49" height="49" rx="24.5" transform="rotate(180 49 49.5)" fill="#720E39"/>
                            <path d="M21 16.4999L29 24.9999L21 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="content">
            <div className="sc__winners">
                <div className="poster__leaf-1 desk-only">
                    <img src={require("../assets/img/leaf-5.svg").default}/>
                </div>
                <div className="winners__leaf-1 mb-only">
                    <img src={require("../assets/img/leaf-1.svg").default}/>
                </div>
                <div className="lk__svg-3">
                    <svg width="81" height="102" viewBox="0 0 81 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <mask id="lk-line-3">
                                <path id="lk-line-3-path"
                                      d="M0.674467 77.0942C12.4638 84.543 45.6922 91.4591 84.2913 59.5328C132.54 19.625 57.4072 -27.2785 35.0673 23.6421C17.1953 64.3787 73.0595 92.1886 103.226 101.002"
                                      fill="none"
                                      stroke="white"
                                      strokeWidth="1.5"
                                />
                            </mask>
                        </defs>
                        <path mask="url(#lk-line-3)" d="M0.674467 77.0942C12.4638 84.543 45.6922 91.4591 84.2913 59.5328C132.54 19.625 57.4072 -27.2785 35.0673 23.6421C17.1953 64.3787 73.0595 92.1886 103.226 101.002" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                    </svg>
                    <div className="lk__svg-icon-3">
                        <img src={require("../assets/img/lk-icon-3.svg").default}/>
                    </div>
                </div>
                <div className="poster__candy-2">
                    <img src={require("../assets/img/candy-1.png")}/>
                </div>
                <div className="container">
                    <div className="sc__header d-flex justify-content-center">
                        <div className="sc__title gradient-text">
                            <span>Победители</span>
                        </div>
                    </div>
                    <div className="lk__wrap">
                        <div className="win__search-wrap d-flex align-items-center justify-content-between">
                            <div className="win__types-list d-flex">
                                <div onClick={()=>{
                                    setWinnersType('week');
                                }} className={'win__types-it' + (winnersType === 'week' ? ' active' : '')}>
                                    Еженедельные призы
                                </div>
                                <div onClick={()=>{
                                    setWinnersType('main');
                                }} className={'win__types-it' + (winnersType === 'main' ? ' active' : '')}>
                                    Главные призы
                                </div>
                            </div>
                            <div className="win__search-bl">
                                <form className={'win__search' + (searchLoading ? ' form--loading' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                    <div className={'form__field' + ((watchEmail && watchEmail != '') ? ' has-value' : '') + (errors.email ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="email-search"
                                            {...register("email")}
                                        />
                                        <label htmlFor="email-search" className="form__label">
                                            Найти себя по эл.почте
                                        </label>
                                    </div>
                                    <button type="submit" className="search__submit">
                                        <svg className="desk-only" width="63" height="55" viewBox="0 0 63 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.109375 0.430908H62.0693V13.9484V26.431V40.9835V54.5011H0.109375V0.430908Z" fill="#CFAC72"/>
                                            <path d="M42.9322 33.8609L37.5177 29.1359C38.36 27.8759 38.8413 26.4059 38.8413 24.8309C38.8413 20.2109 34.5097 16.4309 29.2156 16.4309C23.9214 16.4309 19.5898 20.2109 19.5898 24.8309C19.5898 29.4509 23.9214 33.2309 29.2156 33.2309C31.0204 33.2309 32.7049 32.8109 34.1487 32.0759L39.5632 36.8009C40.5258 37.6409 41.9696 37.6409 42.9322 36.8009C43.8948 35.9609 43.8948 34.7009 42.9322 33.8609ZM29.2156 30.0809C25.8466 30.0809 23.1995 27.7709 23.1995 24.8309C23.1995 21.8909 25.8466 19.5809 29.2156 19.5809C32.5846 19.5809 35.2316 21.8909 35.2316 24.8309C35.2316 27.7709 32.5846 30.0809 29.2156 30.0809Z" fill="white"/>
                                        </svg>
                                        <svg className="mb-only" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.707031 0.0134277H54.7772V13.531V26.0135V40.566V54.0836H0.707031V0.0134277Z" fill="#E9BB6F"/>
                                            <path d="M38.077 33.4434L33.352 28.7184C34.087 27.4584 34.507 25.9884 34.507 24.4134C34.507 19.7934 30.727 16.0134 26.107 16.0134C21.487 16.0134 17.707 19.7934 17.707 24.4134C17.707 29.0334 21.487 32.8134 26.107 32.8134C27.682 32.8134 29.152 32.3934 30.412 31.6584L35.137 36.3834C35.977 37.2234 37.237 37.2234 38.077 36.3834C38.917 35.5434 38.917 34.2834 38.077 33.4434ZM26.107 29.6634C23.167 29.6634 20.857 27.3534 20.857 24.4134C20.857 21.4734 23.167 19.1634 26.107 19.1634C29.047 19.1634 31.357 21.4734 31.357 24.4134C31.357 27.3534 29.047 29.6634 26.107 29.6634Z" fill="white"/>
                                        </svg>

                                    </button>
                                </form>
                                {errors.email && (
                                    <p className="error__message">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        {winners.length > 0 && (
                            <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                <div className="tb__head">
                                    <div className="tb__row">
                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Эл.почта
                                            </div>
                                        </div>
                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Дата
                                            </div>
                                        </div>

                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Приз
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tb__body">
                                    {winners.length > 0 && winners.map((win, i) => (
                                        <div className="tb__row">

                                            <div className="tb__col">
                                                <div className="tb__td">
                                                    <span className="tb-email">{win.user_email}</span>
                                                </div>
                                            </div>
                                            <div className="tb__col">
                                                <div className="tb__td">
                                                    {win.use_at}
                                                </div>
                                            </div>
                                            <div className="tb__col">
                                                <div className="tb__td">
                                                    {win.type_name}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {winners.length === 0 && (
                            <div className="tb__empty">
                                {winnersEmpty}
                            </div>
                        )}
                        {winPaging.total > 1 && (
                            renderPager()
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Winners;
