import {useModal, useProfile} from "../../context";
import Login from "./../modals/Login";
import React from "react";

function CodesWidget({classesList = null, title = 'на сайте', closeNav = null}) {
    const {profile} =  useProfile();
    const { show, hide} = useModal();

    return (
        <>
            <a onClick={()=>{
                if(closeNav){
                    closeNav();
                }
                if(profile.loggedIn && profile.data.promocode) {
                    if(window.promoTools) {
                        window.promoTools.checkGrabberWidget.settings = {
                            skin: 'custom',
                            api: process.env.REACT_APP_API_PROXY,
                            method: '/receipts/api/widgets/receipts',
                            userUuid: profile.data.promocode,
                            i18n: {
                                qr: {
                                    title: 'Сканирование',
                                    subTitle: 'Наведите камеру на QR-код',
                                    btnQr: 'Сканировать QR-код чека',
                                    btnManual: 'Ввести данные вручную',
                                    btnPhotos: 'Загрузить фото чека',
                                    btnBackScanner: 'Назад к сканеру чеков',
                                    cameraError: {
                                        title: '<i class="icon__error"></i><div class="check-grabber__title">нет доступа <br/>к камере</div><div>Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную</div>',
                                    },
                                },
                                manual: {
                                    title: 'Ввести данные <br/> с чека вручную',
                                    subTitle: '',
                                },
                                photos: {
                                    title: 'Загрузка чека',
                                    subTitle: 'Добавьте фото чека',
                                    btnPhotos: 'Отправить',
                                },
                                pending: {
                                    title: 'Подождите!',
                                    subTitle: 'Идет отправка чека',
                                },
                                fail: {
                                    title: 'Не удалось <br/>отправить чек!',
                                },
                                success: {
                                    title: '<i class="icon__success"></i><div>ваш чек успешно зарегистрирован</div>',
                                    subTitle: 'Информация о&nbsp;результатах розыгрыша доступна в&nbsp;разделе Загруженные чеки',
                                },
                            },
                            events: {
                                onSentSuccess(res) {
                                    let elUp1 = document.getElementById('update-codes');
                                    if(elUp1){
                                        elUp1.click();
                                    }

                                    let elUp2 = document.getElementById('inc-codes');
                                    if(elUp2){
                                        elUp2.click();
                                    }
                                },
                            },
                        };
                        window.promoTools.checkGrabberWidget.show();
                    }
                }else {
                    show(<Login/>, "modal--form modal--login");
                }
            }} className={classesList} id="rec-trigger-show">
                <span>{title}</span>
            </a>
        </>
    );
}

export default CodesWidget;
