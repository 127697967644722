import { Link } from 'react-router-dom'
import Info from "./Info";
import {useEffect} from "react";
import axiosInstance from "../../helpers/axios";

function Prize({prize, prize_id, close}){

    useEffect(()=>{
        pushPrize();
    }, []);

    const pushPrize = async () => {
        try {
            const response = await axiosInstance.post('/prizes/api/push/' + prize_id);
            if(response.data){

            }
        } catch (error) {
            if(error.response.data){

            }
        }
    };

    const renderPrizeStatus = (prize) => {
        return(
            <>
                {prize.status == 'doc' && (
                    <div className="modal__subtitle text-center">
                        Ожидайте запрос <br/>
                        документов с почты <br/>
                        <a href="mailto:korkunov@tma-draft.com">korkunov@tma-draft.com</a>
                    </div>
                )}

                {prize.status == 'good' && prize.link && (
                    <div className="d-flex justify-content-center">
                        <a href={prize.link} download target="_blank" className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                            <i className="icon__file">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13" stroke="#E9BB6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5 8L10 13L15 8" stroke="#E9BB6F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10 13V1" stroke="#E9BB6F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </i>
                            <span>скачать</span>
                        </a>
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        вы выиграли <br className="mb-only"/>приз!
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className={'modal__prize' + (prize_id == '1' ? ' modal__prize--main' : '')}>
                        <div className="modal__prize-img">
                            <img src={require('../../assets/img/lk-pr-' + prize_id + '.png')}/>
                        </div>
                        {prize_id == '1' && (
                            <>
                                <div className="lk__prizes-it-title text-center text-uppercase">
                                    500 000 рублей
                                </div>
                                <div className="lk__prizes-it-descr text-center">
                                    на&nbsp;новые свершения
                                </div>
                            </>
                        )}

                        {prize_id == '2' && (
                            <>
                                <div className="lk__prizes-it-title text-center text-uppercase">
                                    Подарочная карта
                                </div>
                                <div className="lk__prizes-it-descr text-center">
                                    на&nbsp;покупку электроники <br/>
                                    для ваших увлечений
                                </div>
                            </>
                        )}

                        {prize_id == '3' && (
                            <>
                                <div className="lk__prizes-it-title text-center text-uppercase">
                                    сертификат
                                </div>
                                <div className="lk__prizes-it-descr text-center">
                                    на&nbsp;покупки для занятий и&nbsp;хобби
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="modal__ct-box z-index-over">
                    {renderPrizeStatus(prize)}
                </div>
                {!prize.link && (
                    <div className="modal__ct-box z-index-over d-flex justify-content-center">
                        <div onClick={()=>{
                            close()
                        }} className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                            <span>Хорошо</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Prize;
