import React, {useLayoutEffect, useState} from "react";
import "keen-slider/keen-slider.min.css"
import axiosInstance from "../helpers/axios";
import anime from "animejs";

const vars = [
    {
        id: 1,
        title: 'больше ценить свои достижения',
        event: 'аppreciate_achievements'
    },
    {
        id: 2,
        title: 'больше уделять себе внимание',
        event: 'pay_more_attention'
    },
    {
        id: 3,
        title: 'видеть больше возможностей',
        event: 'see_more_options'
    },
    {
        id: 4,
        title: 'верить в свои силы',
        event: 'believe_in_yourself'
    },
    {
        id: 5,
        title: 'не бояться ошибаться',
        event: 'no_mistakes'
    },
    {
        id: 6,
        title: 'не бояться просить о помощи',
        event: 'ask_for_help'
    },
    {
        id: 7,
        title: 'изучать что-то новое',
        event: 'learn_new'
    },
    {
        id: 8,
        title: 'развивать свои навыки',
        event: 'improve_skills'
    }
]

function Generator() {
    const [step, setStep] = useState(0);
    const [isDownloading, setIsDownloading] = useState(null);
    const [poster, setPoster] = useState({
        text_id: null,
        text: null,
        bg_id: null,
        bg: null,
        event: null
    });

    useLayoutEffect(()=>{
        if(window.matchMedia("(min-width: 1024px)").matches){
            anim_generator();
        }
    }, []);

    const download = (filename, content) => {
        let element = document.createElement("a");
        element.setAttribute("href", content);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsDownloading(null);
    };

    const downloadImage = async (path, filename) => {
        if(path){
            setIsDownloading(path);
            try {
                if(filename){
                    const result = await fetch(path, {
                        method: "GET",
                        headers: {}
                    });
                    const blob = await result.blob();
                    const url = URL.createObjectURL(blob);
                    download(filename, url);
                    URL.revokeObjectURL(url);
                }
            } catch (error) {
                console.error(error);
                setIsDownloading(null);
            }
        }

    }

    const savePoster = async () => {
        const formData = new FormData();
        formData.append("text", poster.text);
        formData.append("color", poster.bg);

        try {
            const response = await axiosInstance.post('/profiles/api/poster', formData);
            if(response.data){

            }
        } catch (error) {
            if(error.response.data){

            }
        }
    }

    const anim_generator = () => {
        let anim_generator_tl = anime.timeline();
        anim_generator_tl.add({
            targets: '#inner-line-1-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-1,.inner__svg-icon-2,.inner__svg-icon-3,.inner__candy-1,.inner__leaf-1',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '#inner-line-2-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-4,.inner__svg-icon-5',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '#inner-line-3-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-6,.inner__svg-icon-7,.inner__candy-2',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '.anim-generator-text',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        });
    }

    return (
        <div className="content">
            <div className="sc__generator">
                {window.matchMedia("(min-width: 1024px)").matches && (
                    <>
                        <div className="promo__circle-1"/>
                        <div className="promo__circle-2"/>
                        <div className="promo__circle-3"/>
                        <div className="promo__circle-4"/>
                        <div className="inner__svg-1">
                            <svg width="111" height="341" viewBox="0 0 111 341" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <mask id="inner-line-1">
                                        <path id="inner-line-1-path"
                                              d="M66.2425 1.49487C112.956 36.3999 161.509 126.264 -17.9824 206.483C20.2373 228.695 87.9004 291.27 52.7948 363.873"
                                              fill="none"
                                              stroke="white"
                                              strokeWidth="1.5"
                                        />
                                    </mask>
                                </defs>
                                <path mask="url(#inner-line-1)" d="M66.2425 1.49487C112.956 36.3999 161.509 126.264 -17.9824 206.483C20.2373 228.695 87.9004 291.27 52.7948 363.873" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                            </svg>
                            <div className="inner__svg-icon-1">
                                <img src={require("../assets/img/inner-icon-1.png")}/>
                            </div>
                            <div className="inner__svg-icon-2">
                                <img src={require("../assets/img/inner-icon-2.png")}/>
                            </div>
                            <div className="inner__svg-icon-3">
                                <img src={require("../assets/img/inner-icon-3.png")}/>
                            </div>
                            <div className="inner__candy-1">
                                <img src={require("../assets/img/inner-candy-1.png")}/>
                            </div>
                        </div>
                        <div className="inner__leaf-1">
                            <img src={require("../assets/img/inner-leaf-1.svg").default}/>
                        </div>
                        <div className="inner__svg-icon-4">
                            <img src={require("../assets/img/inner-icon-4.png")}/>
                        </div>
                        <div className="inner__svg-2">
                            <svg width="136" height="224" viewBox="0 0 136 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <mask id="inner-line-2">
                                        <path id="inner-line-2-path"
                                              d="M38.255 1.38086C97.3827 37.7253 48.4767 35.4538 12.1317 69.5268C-27.1038 106.31 45.1375 172.93 97.3827 149.647C149.628 126.364 105.901 89.4516 65.5817 129.771C25.262 170.091 70.6927 226.311 135.999 222.336"
                                              fill="none"
                                              stroke="white"
                                              strokeWidth="1.5"
                                        />
                                    </mask>
                                </defs>
                                <path mask="url(#inner-line-2)" d="M38.255 1.38086C97.3827 37.7253 48.4767 35.4538 12.1317 69.5268C-27.1038 106.31 45.1375 172.93 97.3827 149.647C149.628 126.364 105.901 89.4516 65.5817 129.771C25.262 170.091 70.6927 226.311 135.999 222.336" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                            </svg>

                            <div className="inner__svg-icon-5">
                                <img src={require("../assets/img/inner-icon-5.png")}/>
                            </div>
                        </div>
                        <div className="inner__svg-3">
                            <svg width="239" height="188" viewBox="0 0 239 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <mask id="inner-line-3">
                                        <path id="inner-line-3-path"
                                              d="M0.214844 118.948C13.5925 120.326 43.5349 128.274 56.2831 149.048C72.2182 175.017 74.579 211.018 56.2831 231.085C37.9871 251.151 -17.4909 189.181 66.3163 118.948C150.124 48.7157 196.749 48.7157 224.488 72.9135C252.227 97.1114 236.292 163.214 180.223 149.048C124.155 134.883 142.451 78.2243 154.255 48.7147C163.698 25.107 158.189 7.00783 154.255 0.90918"
                                              fill="none"
                                              stroke="white"
                                              strokeWidth="1.5"
                                        />
                                    </mask>
                                </defs>
                                <path mask="url(#inner-line-3)" d="M0.214844 118.948C13.5925 120.326 43.5349 128.274 56.2831 149.048C72.2182 175.017 74.579 211.018 56.2831 231.085C37.9871 251.151 -17.4909 189.181 66.3163 118.948C150.124 48.7157 196.749 48.7157 224.488 72.9135C252.227 97.1114 236.292 163.214 180.223 149.048C124.155 134.883 142.451 78.2243 154.255 48.7147C163.698 25.107 158.189 7.00783 154.255 0.90918" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                            </svg>

                            <div className="inner__svg-icon-6">
                                <img src={require("../assets/img/inner-icon-6.png")}/>
                            </div>


                            <div className="inner__svg-icon-7">
                                <img src={require("../assets/img/inner-icon-7.png")}/>
                            </div>
                        </div>
                        <div className="inner__candy-2">
                            <img src={require("../assets/img/inner-candy-2.png")}/>
                        </div>
                    </>
                )}

                {window.matchMedia("(max-width: 1023px)").matches && (
                    <>
                        {step === 0 && (
                            <div className="inner__decor-1 fadeIn animated"/>
                        )}
                        {step > 0 && (
                            <div className="inner__decor-2 fadeIn animated"/>
                        )}
                        <div className="inner__decor-3"/>
                        <div className="inner__decor-4"/>
                    </>
                )}

                <div className="container">
                    {step === 0 && (
                        <>
                            <div className="sc__header d-flex justify-content-center anim-generator-text">
                                <div className="sc__title text-center text-uppercase gradient-text">
                                    <span>Этой осенью я&nbsp;обещаю себе</span>
                                </div>
                            </div>
                            <div className="generator__label text-center anim-generator-text">
                                <span className="gradient-text">Выберите вариант из&nbsp;списка</span>
                            </div>
                            <div className="d-flex justify-content-center  anim-generator-text">
                                <div className={'var__list' + (poster.text_id ? ' not-empty' : '')}>
                                    {vars.map((varIt, i) => (
                                        <div onClick={()=>{
                                            setPoster({
                                                ...poster,
                                                text_id: varIt.id,
                                                text: varIt.title,
                                                event: varIt.event
                                            })
                                        }} className={'var__it d-flex align-items-center' + (poster.text_id === varIt.id ? ' active' : '')}>
                                            <div className="var__it-decor"/>
                                            <div className={'var__it-title' + ((!poster.text_id || (poster.text_id && poster.text_id === varIt.id)) ? ' gradient-text' : '')}>
                                                {varIt.title}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center  anim-generator-text">
                                <button onClick={()=>{
                                    setStep(1);

                                    if(window.dataLayer) {
                                        window.dataLayer.push({
                                            'event': poster.event
                                        });
                                    }

                                }} type="button" disabled={!poster.text_id} className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                                    Далее
                                </button>
                            </div>
                        </>
                    )}

                    {step === 1 && (
                        <>
                            <div className="sc__header sc__header--bg d-flex justify-content-center anim-generator-text">
                                <div className="sc__title text-center text-uppercase gradient-text">
                                    <span className="desk-only">Этой осенью я&nbsp;обещаю себе</span>
                                    <span className="mb-only">
                                        Выберите <br/>
                                        фон
                                    </span>
                                </div>
                            </div>
                            <div className="generator__label text-center fadeIn animated desk-only">
                                <span className="gradient-text">Выберите вариант фона</span>
                            </div>
                            <div className="d-flex justify-content-center fadeIn animated">
                                <div className={'var__list var__list--bg' + (poster.bg_id ? ' not-empty' : '')}>
                                    <div onClick={()=>{
                                        setPoster({
                                            ...poster,
                                            bg_id: 1,
                                            bg: 'red',
                                        })
                                    }} className={'var__it' + (poster.bg_id === 1 ? ' active' : '')}>
                                        <div className="var__it-decor"/>
                                        <div className="var__it-square var__it-square--1"/>
                                    </div>
                                    <div onClick={()=>{
                                        setPoster({
                                            ...poster,
                                            bg_id: 2,
                                            bg: 'yellow',
                                        })
                                    }} className={'var__it' + (poster.bg_id === 2 ? ' active' : '')}>
                                        <div className="var__it-decor"/>
                                        <div className="var__it-square var__it-square--2"/>
                                    </div>
                                    <div onClick={()=>{
                                        setPoster({
                                            ...poster,
                                            bg_id: 3,
                                            bg: 'blue',
                                        })
                                    }} className={'var__it' + (poster.bg_id === 3 ? ' active' : '')}>
                                        <div className="var__it-decor"/>
                                        <div className="var__it-square var__it-square--3"/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center fadeIn animated">
                                <button onClick={()=>{
                                    setStep(2);
                                    savePoster();
                                }} type="button" disabled={!poster.bg_id} className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                                    <span className="desk-only">Далее</span>
                                    <span className="mb-only">
                                        Создать <br/>
                                        персональный постер
                                    </span>
                                </button>
                            </div>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <div className="generator__result d-flex align-items-center justify-content-center">
                                <div className="generator__result-phone">
                                    <div className="generator__result-phone-img">
                                        <img src={require('../assets/img/posters/poster-' + poster.text_id + '-' + poster.bg_id + '.jpg')}/>
                                    </div>
                                </div>
                                <div className="generator__result-actions">
                                    <button type="button" disabled={isDownloading} onClick={()=>{
                                        setStep(0);
                                        setPoster({
                                            text_id: null,
                                            text: null,
                                            bg_id: null,
                                            bg: null,
                                            event: null
                                        });
                                    }} className="btn-ct btn-ct--generator btn--full d-flex align-items-center justify-content-center">
                                        <div className="gradient-text">
                                            сгенерировать <br/>
                                            новый постер
                                        </div>
                                    </button>
                                    <button type="button" disabled={isDownloading} onClick={()=>{
                                        downloadImage(require('../assets/img/posters/poster-' + poster.text_id + '-' + poster.bg_id + '.jpg'), 'Постер-Обещание.jpg');
                                    }} className="btn-ct btn-ct--generator2 btn--full d-flex align-items-center justify-content-center">
                                        <div className="gradient-text">
                                            Скачать постер
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Generator;
